export const environment = {
  production: true,
  useMocks: true,
  platformCoreApiUrl: 'https://xnu7u481lg.execute-api.us-west-2.amazonaws.com',
  baseApiUrl: 'https://api.swiftterm-dev.com/dev',
  sspUrl: 'https://my.dev.corp.symetra.com/',
  onboardingWebBaseUrl: 'https://swift-dev.symetra.com',
  paymentServiceBaseApiUrl: 'https://swiftcore.swiftterm-dev.com/dev',
  stripePublishableKey:
    'pk_test_51OJj6zDIdlSMBfA38Del9zpX19wohtz48q0oG4VMVavz2y4NX3AmQf4VzfgQKJTyfCgg8j7R9NFY4ESLap4q566800ebhUZ2Cg',
  googleTagManagerID: 'GTM-K9NPPMFQ',
  googleTagManagerEnv: 'env-3',
  googleTagManagerAuth: '-eEy0nPHcDEuQ0AthdwmTA',
  senderrors: true,
  amazonConnectUrl: 'https://zrfj6d3mwg.execute-api.us-west-2.amazonaws.com/Prod',
  amazonConnectContactFlowId: '400f8d53-d30c-4320-866e-3f54186683a1',
  amazonConnectInstanceId: '0373a6bb-5c04-4dcb-84ad-ff5002d68645',
  amazonConnectRegion: 'us-west-2',
  contactFormEndpoint: 'https://symetrafsc--dev.my.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8',
  salesforceDebug: true,
  salesforceDebugEmail: 'automated0tester0sym@gmail.com',
  salesforceOrgId: '00D4C0000008yhH',
  contactFormHoneypotFieldKey: '00N4C000001SObJ',
  contactFormReverseHoneypotFieldKey: '00N4C000001SObK',
  contactPhone: '1-833-447-9438',
  buildId: '20250214.3',
  contractMaxRetries: 8,
  contractRetryIntervalMs: 8000,
  allowDeclineReminders: false,
  loginUrl: "https://auth.swift-dev.symetra.com/oauth2/authorize?client_id=114lic2u2in33b07339p8foi1g&response_type=code&scope=email+openid+profile&redirect_uri=https%3A%2F%2Fswift-dev.symetra.com%2Fcallback",
  symetraHomeUrl: 'https://www.symetra.com/',
  legal : {
    privacyPolicy: 'https://www.symetra.com/admin-pages/privacy/',
    terms: 'https://www.symetra.com/admin-pages/terms-conditions-symetra-online/',
    about: 'https://www.symetra.com/about-us/about-symetra/',
    security: 'https://www.symetra.com/admin-pages/security/',
    caPrivacyNotice: 'https://www.symetra.com/admin-pages/CCPA/',
    legalDisclosure: 'https://www.symetra.com/admin-pages/legal-disclosures/',
    businessContinuity: 'https://www.symetra.com/admin-pages/business-continuity/'
  }
};
